import React, { createContext, useContext, FC, useState } from 'react';

export type Profile = {
    email: string,
    userName: string,
    name: string,
    lastName: string,
    likes: Array<string>,
    dislikes: Array<string>,
    token: string
}

interface IUserContextType {
    name: string,
    lastName: string,
    email: string,
    likes: Array<string>,
    dislikes: Array<string>,
    id: string,
    token: string,
    saveProfile: (profile: Profile) => void,
    clearProfile: () => void,
    setUserLike: (business: string, add: boolean) => void,
    setUserDislike: (business: string, add: boolean) => void,
}

const initialState: IUserContextType = {
    name: '',
    lastName: '',
    email: '',
    likes: [],
    dislikes: [],
    id: '',
    token: '',
    saveProfile: (profile: Profile) => {},
    clearProfile: () => {},
    setUserLike: (business, add) => {},
    setUserDislike: (business, add) => {},
}

const UserContext = createContext<IUserContextType>(initialState);

export const useUserContext = () => useContext(UserContext);

const UserContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [likes, setLikes] = useState<Array<string>>([]);
    const [dislikes, setDislikes] = useState<Array<string>>([]);

    const saveProfile = (profile: Profile) => {
        setName(profile.name);
        setLastName(profile.lastName);
        setEmail(profile.email);
        setLikes(profile.likes);
        setDislikes(profile.dislikes);
        setToken(profile.token);

        localStorage.setItem('userProfile', JSON.stringify(profile));
    }

    const clearProfile = () => {
        setName('');
        setLastName('');
        setEmail('');
        setToken('');
        setLikes([]);
        setDislikes([]);
        localStorage.removeItem('userProfile');
    }
    
     const setUserLike = (business: string, add: boolean) => {
        let tmpLikes: string[]|any;
        
        if(add){
            tmpLikes = likes;
            tmpLikes.push(business);
        }
        else{
            // tmpLikes.pop(business);
            tmpLikes = likes.map(bId => {
                if(bId !== business) return bId;
            });
        }

        setLikes(tmpLikes);
     }

     const setUserDislike = (business: string, add: boolean) => {
        let tmpDisLikes: string[]|any;
        
        if(add){
            tmpDisLikes = dislikes;
            tmpDisLikes.push(business);
        }
        else{
            // tmpLikes.pop(business);
            tmpDisLikes = dislikes.map(bId => {
                if(bId !== business) return bId;
            });
        }

        setDislikes(tmpDisLikes);
     }

    return(
        <UserContext.Provider value={{id, name, lastName, email, token, likes, dislikes, 
            saveProfile, clearProfile, setUserLike, setUserDislike}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;