const API_KEY = process.env.REACT_APP_API;
const API_TOKEN = process.env.REACT_APP_TOKEN;

export async function login (loginData: any){
    return await fetch(`${API_KEY}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      })
      .then(async(response) =>{
        if(!response.ok){
          return null;
        }
        const dataResponse = await response.json();
        return dataResponse;
      });
}

export async function registerNewUser(userData: any) {
  return await fetch(`${API_KEY}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + API_TOKEN
    },
    body:JSON.stringify(userData)
  })
  .then(async(response) => {
    const dataResponse = await response.json();
    return {...dataResponse, status: response.status}
  })
  .catch(error => {
    console.error('Error: ', error);
    return null;
  });
}