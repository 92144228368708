import { useQuery } from '@tanstack/react-query';

import { getSubCatBusiness } from '../services/ziti';

const QUERY_KEY:any = ['SubCategoriesBusiness'];

const fetchData = async(subCatId: string) => {
    const result = await getSubCatBusiness(subCatId);
    console.log('getSubCatBusiness: ' + subCatId);
    return result;
}

const useSubCategoriesData = (subCatId: string) => {
    return useQuery({queryKey: [QUERY_KEY, subCatId], queryFn:() => fetchData(subCatId) , refetchInterval:30000});
}

export default useSubCategoriesData;