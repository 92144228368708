import ziti_logo from '../assets/ziti_icon.png';

export const validateString = (str: string, isAlpha: boolean = false): boolean => {
    var regex = /^[a-zA-Zá-úÁ-Ú]+(?:\s[a-zA-Zá-úÁ-Ú]+)*$/;
    
    if(isAlpha) regex = /^[a-zA-Zá-úÁ-Ú0-9%]+(?:\s[a-zA-Zá-úÁ-Ú0-9%]+)*$/;

    return regex.test(str);
}

// Function to valide email format
export const validateEmail = (email:string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

export const getAssetUrl = (bId: string, strLogo: string):string => {
    const baseAssetURl = process.env.REACT_APP_IMAGE_SERVER + bId + '/';
    
    if(strLogo !== ''){
        return baseAssetURl + strLogo;
    }

    return ziti_logo;
}