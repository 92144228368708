import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';

import { Profile, useUserContext } from '../../store/user';

import { validateString, validateEmail } from '../../utils';

import { registerNewUser } from '../../services/ziti.users';

import Input from '../../components/Input';
import Loading from '../../components/Loading';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import fullLogo from '../../assets/logo_lyrics.png';
import logo from '../../assets/logo.png';
import doneOK from '../../assets/animations/ok_done.json';

enum ErrorInput  {
    None,
    Name,
    LastName,
    Email,
    Password,
    DiffPassword,
    RegisteredEmail,
}

enum Status {
    None,
    Saving,
    Saved,
    Error,
}

const Register = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState<ErrorInput>(ErrorInput.None);
    const [registerStatus, setRegisterStatus] = useState<Status>(Status.None);
    
    const { saveProfile, token } = useUserContext();
    const navigate = useNavigate();

    useEffect(()=> {
        const profileLocal = localStorage.getItem('userProfile');
        if(profileLocal){
           navigate('/Profile') 
        }
    },[]);

    const inputNameHandler = (value: string) => { setName(value); setError(ErrorInput.None); }
    const inputLastNameHandler = (value: string) => { setLastName(value); setError(ErrorInput.None); }
    const inputEmailHandler = (value: string) => { setEmail(value); setError(ErrorInput.None); }
    const inputPasswordHandler = (value: string) => { setPassword(value); setError(ErrorInput.None); }
    const inputRepeatPassword = (value: string) => { setPassword2(value); setError(ErrorInput.None); }

    const registerUser = async() =>{
        setError(ErrorInput.None);
        
        if(!validateString(name)) {
            setError(ErrorInput.Name);
            return;
        }
        else if(!validateString(lastName)) {
            setError(ErrorInput.LastName);
            return;
        }
        else if(!validateEmail(email)) {
            setError(ErrorInput.Email);
            return;
        }
        else if(!validateString(password, true)){
            setError(ErrorInput.Password);
            return;
        }
        else if(password !== password2) {
            setError(ErrorInput.DiffPassword);
            return;
        }

        const result = await registerNewUser({
            'name': name,
            'lastName': lastName,
            'userName': email,
            'email': email,
            'pwd': password,
          });

        if(result?.status === 201){
            let profile:Profile = {
                name,
                lastName,
                'userName': email,
                email,
                likes: [],
                dislikes: [],
                token: result.token
              };

              saveProfile(profile);

              setRegisterStatus(Status.Saved);
        }
        else if(result?.status === 409){
            setError(ErrorInput.RegisteredEmail);
        }
        else{
            setRegisterStatus(Status.Error);
        }
    }

    const registerCallback = () =>{
        if(registerStatus !== Status.None) return;
        registerUser();
    }

    const retryRegisterCallback = () =>{
        if(registerStatus !== Status.Error) return;
        registerUser();
    }

    const ErrorString = [
        'Por favor verifica tu nombre este correctamente escrito.',
        'Por favor verifica tus apellidos esten correctamente escritos.',
        'Correo incorrecto, por favor verifica que este correctamente escrito.',
        'Contraseña incorrecta, debe ser minimo 6 Caracteres: solo puede contener Letras, Numeros y Caracteres especiales como @$!%.*& , No puede contener espacios.',
        'Las contraseñas son diferentes, por favor verificalas.',
        'Este usuario ya esta registrado!. Puedes intentar recuperar la contraseña.'
    ];

    const RegisterRenderObj = [
        <div className='w-[90%] lg:w-[65%] 2xl:w-[40%] mt-5 flex flex-col items-center'>
            <div className='flex flex-col sm:flex-row w-full sm:space-x-10'>
                <div className='w-full'>
                    <Input type="text" id="name" label='Nombre' placeHolder='Juan' changeHandler={inputNameHandler} 
                        error={error === ErrorInput.Name}/>
                </div>
                <div className='w-full mt-8 sm:mt-0'>
                    <Input type="text" id="lastname" label='Apellidos' placeHolder='Perez Garcia' changeHandler={inputLastNameHandler} 
                        error={error === ErrorInput.LastName}/>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row mt-8 w-full sm:w-[90%] sm:space-x-10'>
                <div className='w-full '>
                    <Input type="email" id="email" label='Correo' placeHolder='correo@mail.com' changeHandler={inputEmailHandler} 
                        error={error === ErrorInput.Email || error === ErrorInput.RegisteredEmail}/>
                </div>
                <div className='w-full mt-8 sm:mt-0'>
                    <div className='w-full'>
                        <Input type="password" id="pwd" label='Contraseña' placeHolder='******' changeHandler={inputPasswordHandler} 
                            error={error === ErrorInput.Password || error === ErrorInput.DiffPassword}/>
                    </div>
                    <div className='w-full mt-8 sm:mt-4'>
                        <Input type="password" id="pwd2" label='Repetir Contraseña' placeHolder='******' changeHandler={inputRepeatPassword} 
                            error={error === ErrorInput.DiffPassword}/>
                    </div>
                </div>
            </div>
            <div className='w-full mt-4 flex flex-col items-center justify-center'>
                { error !== ErrorInput.None &&
                    <div className='bg-red-600/20 rounded border  w-full sm:w-[60%] mih-h-10 h-full p-2 text-center text-red-600 flex flex-col items-center'>
                        <span>{ErrorString[error-1]}</span>
                        { error === ErrorInput.RegisteredEmail &&
                            <Link to='RecoveryPassword' className='mt-2 mb-2 italic cursor-pointer hover:text-2xl text-blue-600'>Recuperar Contraseña</Link>
                        }
                    </div>
                }
            </div>
            <button className='bg-sun_yellow mt-5 mb-10 h-10 text-xl rounded-lg hover:shadow-lg w-[90%] sm:w-[30%]'
                onClick={() => registerCallback()}>
                Registrarse
            </button>
        </div>,
        <div className='w-[80%] sm:w-[30%] mt-20'><Loading /></div>,
        <div className='w-full sm:w-[70%] p-1 flex flex-col items-center'>
            <div className='sm:w-[60%] lg:w-[45%] xl:w-[30%]'><Lottie animationData={doneOK} play loop={false}/></div>
            <p className='text-2xl 2xl:text-3xl text-center'>Ahora puedes disfrutar de los descuentos exclusivos que tenemos para ti</p>
            <button className='mt-10 bg-pink_ziti shadow-md hover:shadow-xl w-[80%] sm:w-[60%] lg:w-[50%] xl:w-[40%] 2xl:w-[35%] h-14 flex flex-row items-center justify-center rounded-full'
            onClick={() => navigate('/Ziti')}>
                <img className='h-10' src={logo} />
            </button>
            <button className='btn-square w-[50%] lg:w-[40%] xl:w-[30%]' onClick={() => navigate('/Profile')}>ir al perfil</button>
        </div>,
        <div className='w-full sm:w-[70%] xl:w-[50%] flex flex-col items-center justify-center'>
            <FontAwesomeIcon className='w-16 h-16 mt-10 mb-5' icon={ faExclamationTriangle } color='red'/>
            <p className='font-ArialRounded text-center text-2xl text-red-600'>Hubo un error al guardar tus datos, puedes reintar en un momento. Si el problema periste contacta a soporte.</p>
            <button className='btn-square w-[50%]'
                onClick={() => retryRegisterCallback()}>Reintentar</button>
        </div>
    ]

    return(
        <div className='font-ArialRounded'>
            <div className='w-full p-1 flex flex-col items-center justify-center'>
                {/* <button className='bg-red-500 w-100 h-12' onClick={() => setRegisterStatus(Status.Error)}>failbtn</button> */}
                { registerStatus === Status.None && 
                    <>
                        <img src={fullLogo} className='h-32 mt-4' alt='ZiTi' />
                        <p className='mt-5 mb-5 text-xl sm:text-2xl text-center w-full sm:w-[70%]'>Descubre las mejores promociones y descuentos antes que nadie.</p>
                    </>
                }
                { RegisterRenderObj[registerStatus]}
            </div>
        </div>
    );
}

export default Register;