import { useQuery } from '@tanstack/react-query';

import { getZitiPromos } from '../services/ziti';

const QUERY_KEY:any = ['ZitiDiscounts'];

// enum PromotionType  {
//     discount,
//     promo,
//     product
// }

// export type Promotion = {
//     _id: string,
//     product: string,
//     description: "descuento en cafe americano",
//     discountAmount: "10",
//     type: PromotionType,
//     requirement: string,
//     businessId: string,
//     usersOnly: boolean,
//     mainPromo: boolean
// }

const fetchData = async() => {
    const result = await getZitiPromos();
    console.log('getZitiDiscounts');
    return result;
}

const useZitiDiscountsData = () => {
    return useQuery({queryKey: [QUERY_KEY], queryFn:() => fetchData() , refetchInterval:30000});
}

export default useZitiDiscountsData;