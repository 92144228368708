import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEye } from '@fortawesome/free-solid-svg-icons';

interface InputProps {
    id: string,
    type: string,
    label: string,
    placeHolder: string,
    error: boolean,
    changeHandler: (value: string) => void
}

type StatusStyle = {
    bg: string,
    border: string,
    text: string
}

const StatusDefault: StatusStyle ={
    bg: 'bg-white',
    border:'border-slate-950/50',
    text:''
}

const Input = ({id, type, label, placeHolder, error, changeHandler}: InputProps) => {
    const [inputType, setInputType] = useState(type);
    const [reveal, setReveal] = useState(false);
    const [hiddenOpacity, setHiddenOpacity] = useState('opacity-20');
    const [validateStyle, setValidateStyle] = useState<StatusStyle>(StatusDefault);

    useEffect(()=>{
        if(error){
            setValidateStyle({
                bg: 'bg-red-100',
                border:'border-red-400',
                text:'text-red-400'
            });
        }
        else{
            setValidateStyle(StatusDefault);
        }
    }, [error]);

    const revealPassword = () => {
        if(reveal){
            setInputType("password");
            setReveal(false);
            setHiddenOpacity('opacity-20');
        }
        else{
            setInputType("text");
            setReveal(true);
            setHiddenOpacity('opacity-50');
        }
    }

    return(
        <div>
             { label && <span className={`mt-8 text-lg mb-6 ${validateStyle.text}`}>{ label }</span> }
            <div className={`bg-white relative flex flex-row items-center justify-center rounded border-2 ${validateStyle.border}`}>
            <input
                type={inputType}
                className={`peer block min-h-[auto] w-full px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear 
                focus:placeholder:opacity-30 data-[te-input-state-active]:placeholder:opacity-100 
                motion-reduce:transition-none text-black placeholder:text-black/50 placeholder:italic ${validateStyle.bg}`}
                id={id}
                onChange={ e => changeHandler(e.target.value) }
                placeholder={placeHolder} />
            { type === 'password' &&
                <button onClick={() => revealPassword()}>
                    <FontAwesomeIcon icon={faEye}
                        className={'absolute cursor-pointer h-6 bottom-3 right-2 bg-transparent ' + hiddenOpacity}  />
                </button>
            }
            </div>
        </div>
    )
}

export default Input;