import { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, RedirectFunction } from 'react-router-dom';

import { useUserContext } from '../../store/user';

import { login } from '../../services/ziti.users';
import { validateEmail } from '../../utils';

import fullLogo from '../../assets/logo_lyrics.png';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [pwd, setPwd] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const { saveProfile, email } = useUserContext();

    // const { usr } = useUser();
    useEffect(()=> {
        const profileLocal = localStorage.getItem('userProfile');
        if(profileLocal){
           navigate('/Profile') 
        }
    },[]);
    

    const handleLoginSubmit = async() => {
        if(loading) return;
    
        if(!validateEmail(userName)){
            setError(true);
            setErrorMessage('Por favor verifica el email');
            return;
        }
        setError(false);
        const dataLogin = {
            user: userName,
            pwd
        }
        setIsLoading(true);

        const res = await login(dataLogin);
        setIsLoading(false);
        
        if(res){
            
            let profile = res.data;
            profile.token = res.token;
            
            saveProfile(profile);
            
            navigate('/Profile');
        }
        else{
            setError(true);
            setErrorMessage('Usuario o contraseña incorrectos.');
        }
    }

    const goToRegister = () => { navigate('/Register'); }

    return(
        <div className=" outer-div flex justify-center items-center font-BasierSquare text-black flex-col">
            <img src={fullLogo} className="h-32 mt-4" alt="ZiTi" />
            <div className='w-full md:w-[90%] lg:w-[70%] xl:w-[55%] 2xl:w-[45%]
                p-4 flex flex-col sm:flex-row'>
                <div className="inner-div px-8 w-full sm:w-[50%] h-auto flex flex-col items-center">
                    <span className='mt-8 text-2xl mb-6'>Ingresa a tu cuenta</span>
                    <div className="w-full mt-2">
                        <input
                            type="text"
                            className="peer block min-h-[auto] w-full rounded border-slate-950/50 border 
                            bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all 
                            duration-200 ease-linear focus:placeholder:opacity-30 data-[te-input-state-active]:placeholder:opacity-100 
                            motion-reduce:transition-none text-black placeholder:text-black"
                            id="inputUser"
                            onChange={ e => setUserName(e.target.value) }
                            placeholder="Usuario" />
                    </div>
                    <div className=" w-full mt-10">
                        <input
                            type="password"
                            className="peer block min-h-[auto] w-full rounded border-slate-950/50 border 
                            bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all 
                            duration-200 ease-linear focus:placeholder:opacity-30 data-[te-input-state-active]:placeholder:opacity-100 
                            motion-reduce:transition-none text-black placeholder:text-black"
                            id="exampleFormControlInput22"
                            onChange={ e => setPwd(e.target.value) }
                            placeholder="Contraseña" />
                    </div>
                    {error && (
                        <div className='mt-5 bg-red-200 border-2 border-red-600 rounded p-2 text-md w-full justify-center content-center text-center text-red-600'>
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    <button className="mt-12 bg-yellow-500 w-3/4 h-12 rounded text-xl font-semibold shadow-xl" type="button" onClick={() => handleLoginSubmit()}>Iniciar Sesión</button>
                </div>
                <div className="hidden sm:display w-1 mx-2 py-2 sm:flex flex-col justify-center">
                    <div className="bg-gray/20 w-full h-[70%]"/>
                </div>
                <div className='p-8 flex flex-col w-full sm:w-[40%] mx-auto items-center justify-center'>
                    <span className='mt-6 mb-4 text-2xl text-center'>¿Aun no tienes cuenta?</span>
                    <button className="bg-yellow-500 w-[100%] mt-4 h-12 rounded text-xl font-semibold shadow-xl" type="button" onClick={() => goToRegister()}>Regístrate</button>
                </div>
            </div>
        </div>
    );
}

export default Login;