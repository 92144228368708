import { useQuery } from '@tanstack/react-query';

import { getBusinessInfo } from '../services/ziti.business';

const QUERY_KEY:any = ['BusinessInfo'];

const fetchData = async(businessUser: string) => {
    const result = await getBusinessInfo(businessUser);
    console.log('getBusinessInfo: ' + businessUser);
    return result;
}

const useBusinessData = (businessUser: any) => {
    return useQuery({
        queryKey: [QUERY_KEY, businessUser], 
        queryFn:() => fetchData(businessUser) , 
        refetchInterval:30000, 
        
        enabled:!!businessUser});
}

export default useBusinessData;