
type ZitiDiscountCardProps = {
    logo: string,
    name: string,
    description: string,
    textPromo: string
}

const ZitiDiscountCard = ({logo, name, description, textPromo}: ZitiDiscountCardProps) => {
    return(
        <div className="relative w-full h-48 hover:scale-110 cursor-pointer transition-transform hover:z-10">
            <div className="bg-black/60 w-full h-full rounded-t-3xl"></div>
            <div className="w-full h-16 bg-pink_ziti rounded-2xl absolute top-0 flex flex-row items-center py-2 pl-2 pr-2">
                <div className="h-12 w-12 ml-1">
                    <img className="w-full h-full" src={logo} alt="ziti" />
                </div>
                <div className="flex flex-col w-[75%] pl-2 pr-1 justify-center">
                    <span className="text-white font-bold text-lg text-left truncate overflow-hidden text-nowrap">{name}</span>
                    <span className="text-white w-full text-sm text-center truncate overflow-hidden text-nowrap">{description}</span>
                </div>
            </div>
            <div className="absolute bottom-0 w-full h-32 p-2 flex flex-grow justify-center items-center">
                <span className="text-white text-2xl text-center ">{textPromo}</span>
            </div>
        </div>
    )
}

export default ZitiDiscountCard;