import React,{ useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useJwt } from "react-jwt";

import { useUserContext } from "../store/user";

import Layout from '../pages/Layout';
import BusinessLayout from "../pages/BusinessLayout";

import Home from '../pages/Home';
import SubCategories from "../pages/SubCategories";
import Business from "../pages/Business";
import BusinessDetail from "../pages/BusinessDetail";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import Register from "../pages/Register";
import ZitiDiscounts from "../pages/ZitiDiscounts";

export default function RouterApp() {
    const { saveProfile, token, clearProfile } = useUserContext();
    const { isExpired,reEvaluateToken, decodedToken } = useJwt(token);

    useLayoutEffect(()=> {
        const profileLocal = localStorage.getItem('userProfile');
        if(profileLocal){
            saveProfile(JSON.parse(profileLocal));
        }
    },[]);

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Layout /> } >
                    <Route index element={ <Home /> } />
                    <Route path="Categories" element={ <SubCategories /> } />
                    <Route path="Business" element={ <BusinessLayout /> } >
                        <Route index element={ <Business /> } />
                        <Route path=":id" element={ <BusinessDetail/> } />
                    </Route>
                    <Route path="Login" element={ <Login /> } />
                    <Route path="Profile" element={ <Profile /> } />
                    <Route path="Register" element={ <Register /> } />
                    <Route path="Ziti" element= { <ZitiDiscounts /> } />
                    <Route path="*" element={ <h1 className="text-red-800 text-5xl">No Page!!{process.env.REACT_APP_TESTVAR}</h1>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}