import { useQueryClient, useQuery, QueryFunction } from "@tanstack/react-query";

import { getAllCategories } from "../services/ziti";

const QUERY_KEY:any = ['Categories'];

const fetchData = async() => {
    const categoriesData = await getAllCategories();
    return categoriesData;
}

const useCategoriesData = () => {
    return useQuery({queryFn:fetchData , queryKey: QUERY_KEY, refetchInterval:300000});
}

export default useCategoriesData;