const API_KEY = process.env.REACT_APP_API;

export function getAllCategories(){
  console.log(API_KEY)
  return fetch(`${API_KEY}/category`,{
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
  .catch(error => {
    console.error(error);
    return null;
  })
}

export function getSubCatBusiness(subcatId: string) {
  return fetch(`${API_KEY}/subcategory/${subcatId}/business`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(error => {
      console.error(error);
    });
}

export function getZitiPromos() {
  return fetch(`${API_KEY}/zitipromos/`,{
    method: 'GET',
    headers:{
      'Content-Type': 'application/json'
    }
  })
  .then(response => response.json())
  .then(responseJson =>{
    return responseJson;
  })
  .catch(error => console.error(error));
}
