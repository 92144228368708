import React, { createContext, useContext, FC, useState } from 'react';

export type BusinessInfo = {
    geolocalization: { lat: string, lon: string },
    likes: number,
    dislikes: number,
    id: string,
    userName: string,
    name: string,
    description: string,
    email: string,
    phone: string,
    address: string,
    url: string,
    url_fb: string,
    url_insta: string,
    url_aldente: string,
    addedDate: string,
    subcategoriesId: string,
    img_logo: string,
    img_background: string,
    mainBanner: Array<string>,
    secondBanner: Array<string>,
    assetUrl: string,
    schedule: Array<{}>,
    // "schedule": [
    //     {
    //         "day": 0,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 1,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 2,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 3,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 4,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 5,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     },
    //     {
    //         "day": 6,
    //         "hStart": "12:00",
    //         "hClose": "13:00"
    //     }
    // ],
}

interface IBusinessContext {
    business: BusinessInfo
    clearContext: () => void,
    selectBusiness : (business: BusinessInfo) => void,
    likeBusiness: (add: number) => void,
    dislikeBusiness: (add: number) => void,
    // setCategory: (selectedCat: SelectedCategory) => void,
}

const initialState: IBusinessContext = {
    business: {
        geolocalization: {
            lat: '',
            lon: ''
        },
        likes: 0,
        dislikes: 0,
        id: '',
        userName: '',
        name: '',
        description: '',
        email: '',
        phone: '',
        address: '',
        url: '',
        url_fb: '',
        url_insta: '',
        url_aldente: '',
        addedDate: '',
        subcategoriesId: '',
        img_logo: '',
        img_background: '',
        mainBanner: [],
        secondBanner: [],
        assetUrl: '',
        schedule:[]
    },
    clearContext:() => {},
    selectBusiness : (business: BusinessInfo) => {},
    likeBusiness: (add) => {},
    dislikeBusiness: (add) => {},
}

const BusinessContext = createContext<IBusinessContext>(initialState);

export const useBusinessContext = () => useContext(BusinessContext);

const BusinessContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
    const [business, setBusiness] = useState<BusinessInfo>({
        geolocalization: {
            lat: '',
            lon: ''
        },
        likes: 0,
        dislikes: 0,
        id: '',
        userName: '',
        name: '',
        description: '',
        email: '',
        phone: '',
        address: '',
        url: '',
        url_fb: '',
        url_insta: '',
        url_aldente: '',
        addedDate: '',
        subcategoriesId: '',
        img_logo: '',
        img_background: '',
        mainBanner: [],
        secondBanner: [],
        assetUrl: '',
        schedule:[]
    });

    const clearContext = () => {}

    const selectBusiness = (business: BusinessInfo) => setBusiness(business);

    const likeBusiness = (add: number) => {
        let businessBkp = business;
        businessBkp.likes = businessBkp.likes + add;

        setBusiness(businessBkp);
    }
    const dislikeBusiness =  (add: number) => {
        let businessBkp = business;
        businessBkp.dislikes = businessBkp.dislikes + add;

        setBusiness(businessBkp);
    }

    return(
        <BusinessContext.Provider value={{clearContext, selectBusiness, business, likeBusiness, dislikeBusiness}}>
            {children}
        </BusinessContext.Provider>
    )
};

export default BusinessContextProvider;