import React from 'react';

type ItemButtonProps = {
    id: string,
    label: string,
    subCategories: any,
    clickCallback: (id: string, label: string, categories: any) => void,

}

const ItemButton = ({id, label, subCategories, clickCallback} : ItemButtonProps) => {
    return(
        <button className='bg-sun_yellow shadow-md rounded-full min-h-14 lg:min-h-16 text-center items-center'
            onClick={() => clickCallback(id, label, subCategories)}>
            <p className='text-slate-50 text-xl md:text-2xl lg:text-3xl text-wrap'>{label}</p>
        </button>
    )
}

export default ItemButton;