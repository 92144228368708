import { useState, useEffect, ReactNode } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';

import usePromotionsData, { Promotion } from "../../hooks/usePromotionsData";
import useBusinessData from "../../hooks/useBusinessData";

import { useBusinessContext } from "../../store/business";
import { useUserContext } from "../../store/user";

import { setLikeBusiness, getAssetUrl } from "../../services/ziti.business";

import { cn } from "../../utils/cn";

import Loading from "../../components/Loading";
import PromotionCard from "../../components/PromotionCard";
import Slider from "../../components/Slider";

import logo_fb from '../../assets/facebook.png';
import logo_insta from '../../assets/instagram.png';
import logo_aldente from '../../assets/aldente.png';
import like from '../../assets/like.png';
import ziti_logo from '../../assets/ziti_icon.png';
import dislike from '../../assets/notlike.png';

type BusinessTime = {
    open: boolean,
    time: string
}

const BusinessDetail = () => {
    const [mainBannerImg, setMainBannerImg] = useState<Array<string>>([]);
    const [secondBanner, setSecondBannerImg] = useState<Array<string>>([]);
    const [schedule, setSchedule] = useState<BusinessTime>({open: false, time: ''});
    const [sliderObj, setSliderObj] = useState<Array<ReactNode>>([]);
    const [isLoadingBusiness, setIsloadingBusiness] = useState(false);

    const { business, selectBusiness, likeBusiness, dislikeBusiness } = useBusinessContext();
    const promotionsData = usePromotionsData(business.userName);
    const { email, token, likes, dislikes, setUserLike, setUserDislike, clearProfile } = useUserContext();
    const navigate = useNavigate();
    const {id} = useParams();
    const businessData = useBusinessData(id);
    const queryClient = useQueryClient();

    useEffect(() => {
        if(!business.userName){
            setIsloadingBusiness(true);
            queryClient.refetchQueries({ queryKey: [['BusinessInfo'], id] });
        }
    },[]);

    useEffect(() => {
        if(businessData.data){
            const data = businessData.data;
            let bData = data.business[0];
            
            const assets = getAssetUrl(bData.userName, bData.logo, bData.background);

            bData.assetUrl = assets.baseUrl;
            bData.img_logo = assets.logo;
            bData.background = assets.background;

            selectBusiness(bData);
            setIsloadingBusiness(false)       
        }
    }, [businessData.data])

    useEffect(() =>{
        setCurrentSchedule();
        let imgBanner: Array<string> = [];
        let tmpSlider: Array<ReactNode> = [];

        business.mainBanner.map((img: string, index: number) =>{
            if(img!==''){
                imgBanner.push(business.assetUrl + img);
                tmpSlider.push(<img className='w-full object-contain' src={business.assetUrl + img} />);
            }
        });
        setMainBannerImg(imgBanner);
        setSliderObj(tmpSlider);

        imgBanner =[];
        business.secondBanner.map(img =>{
            if(img!=='') imgBanner.push(business.assetUrl + img);
        });

        setSecondBannerImg(imgBanner);
    }, [business]);

    const setCurrentSchedule = () =>{    
        var aDate = new Date();
        var today = aDate.getDay();
        let open = false;
        let time: string = '';
    
        if(business.schedule && business.schedule.length >0){
            business.schedule.map((dayOpen: any) => {
            if(dayOpen.day === today){
              const currentHour = aDate.getHours();
              const currentMinute = aDate.getMinutes();
    
              const hCloseParts = dayOpen.hClose.split(":");
              const hStartParts = dayOpen.hStart.split(":");
    
              const hClose = new Date();
              hClose.setHours(parseInt(hCloseParts[0], 10));
              hClose.setMinutes(parseInt(hCloseParts[1], 10));
    
              const hStart = new Date();
              hStart.setHours(parseInt(hStartParts[0], 10));
              hStart.setMinutes(parseInt(hStartParts[1], 10));
              time = dayOpen.hStart + ' - ' + dayOpen.hClose;
    
              if(aDate.getTime() >= hStart.getTime() &&
                aDate.getTime() <= (hClose.getTime()))
                {
                  open = true;
                }
            }
          })
        }

        setSchedule({ open, time });
      }

    const getPromoBgImage = (imgs: string):string => {
        if(imgs !== ''){
            return business.assetUrl + 'promos/' + imgs;
        }
        
        return ziti_logo;
    }
    
    const renderPromos = () => {
        let promos:any = [];

        if(promotionsData.isLoading) {
            return(
                <div className="flex flex-row items-center justify-center">
                    <div className="h-full w-24"><Loading /></div>
                </div>
            );
        }
        else if(promotionsData.data){
            promos.push(promotionsData.data.map((promo: Promotion, index:number) => {
                let promoText = promo.discountAmount?promo.discountAmount:'';
                promoText += promo.type===1?'%':'';
                promoText += ' ' + promo.product;
                return(<PromotionCard key={index} promoText={promoText} bgImage={getPromoBgImage(promo.image)} />)
             }));

             return promos;
        }
    }
 
    const likeLogic = async(like: any) => {
        let businessId = business.userName;
    
        // let userLikes = likes;
        // let userDisLikes = dislikes;
    
        if(likes.length === 0 && dislikes.length === 0){
          if(like){
            //   business.likes += 1;
            likeBusiness(1);
            //   userLikes.push(businessId);
              setUserLike(businessId, true)
          }
          else{
            //   business.dislikes += 1;
            dislikeBusiness(1);
            //   userDisLikes.push(businessId);
              setUserDislike(businessId, true);
          }
        }
        else{
            var findedLike = likes.includes(businessId);
            var findedDisLike = dislikes.includes(businessId);
            
            // findedLike = likes.find((like: string) => like === businessId);
            // findedDisLike = dislikes.find((dislike: string) => dislike === businessId);

            if(like){
                if(!findedLike){
                    // userLikes.push(businessId);
                    setUserLike(businessId, true);
                    // business.likes += 1;
                    likeBusiness(1);
                }
                if(findedDisLike){
                    // userDisLikes.pop(businessId);
                    setUserDislike(businessId, false);
                    if(business.dislikes > 0) dislikeBusiness(-1);//business.dislikes -= 1;
                }
            }
            else{                
                if(findedLike){
                    // userLikes.pop(businessId);
                    setUserLike(businessId, false);
                    if(business.likes > 0) likeBusiness(-1);//business.likes -= 1;
                }
                if(!findedDisLike){
                    // userDisLikes.push(businessId);
                    setUserDislike(businessId, true);
                    // business.dislikes += 1;
                    dislikeBusiness(1);
                }
            }
        }
        // setLik userLikes;
        // user.dislikes = userDisLikes;
    
        // changeShop(business);
        // this.props.context.setProfile(user);
    
        //TODO save on localstorage
        // const stringProfile = JSON.stringify(user);
        // await localStorage.set(USER_DATA, stringProfile);
    
      }

    const giveLikeBusiness = async(like: boolean) => {

        if(!email ||email === ''){
            navigate('/Login');
          return;
        }
    
        const rest = business.userName;
        const data ={
          'usr': email,
          'like': like
        };
    
        
        if(like && likes.includes(rest)){
            return;
        }
        else if(!like && dislikes.includes(rest)){
            return;
        }
        const subCatId = business.subcategoriesId;
        const res = await setLikeBusiness(rest, data, token);

        if(res){
            if(res === 200){
                queryClient.refetchQueries({ queryKey: [['SubCategoriesBusiness'], subCatId] });
                likeLogic(like);
            }
            else if(res === 401){
                clearProfile();
                navigate('/Login');
            }
        }
    }

    if(isLoadingBusiness) return(
        <div className="flex flex-grow items-center justify-center p-4">
            <div className="sm:mt-[10%] lg:mt-0 sm:w-[30%] ">
                <Loading />
            </div>
        </div>
    )

    return(
        <div className="p-1 sm:p-4 flex-col flex justify-center items-center">
            <div className="sm:h-28 flex flex-col sm:flex-row items-center">
                <img className="h-full w-28" src={business.img_logo} alt={business.name}></img>
                <div className="flex flex-col p-2 ml-4">
                    <span className="font-ArialRounded text-center text-3xl">"{business.name}"</span>
                    <span className="text-center mt-2 font-BasierSquare text-lg">{business.description}</span>
                </div>
            </div>
            <div className="w-full xl:w-[70%]">
                <div className="bg-pink_ziti/30 mt-4 h-38 sm:h-64 w-full rounded-2xl overflow-hidden">
                    <Slider gallery={ sliderObj } autoSlide={true} autoSlideInterval={3000} showButtons={true} emptyGalleryObject={<EmptyGallery />}/>
                </div>
                <div className="w-full flex flex-col-reverse sm:flex-row mt-1 h-58">
                    <div className=" sm:w-[49%] p-4 flex flex-col items-center">
                        <div className="text-center font-BasierSquare text-xl">
                            <span>Promociones</span>
                        </div>
                        <div className="mt-2 h-full w-full overflow-y-hidden overflow-x-scroll">
                            <div className="whitespace-nowrap mt-1">
                                { renderPromos() }
                            </div>
                        </div>
                        <button className="bg-pink_ziti p-1 w-[80%] font-ArialRounded text-white mt-4"
                            onClick={() => navigate('/Ziti')}>Promociones Especiales</button>
                    </div>
                    <div className="hidden sm:block w-1 mx-2 py-2">
                        <div className="bg-black w-full h-full"/>
                    </div>
                    <div className="sm:w-[49%] p-4">
                        <div className=" w-full sm:h-[50%] flex flex-col-reverse sm:flex-row">
                            <div className="bg-cyan-400 hidden mt-4 h-32 sm:h-auto sm:mt-0 w-full sm:w-[49%]">
                                <iframe
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBhseLWsmzuwV8SRYj-SKz-CQZD82U1TxM
                                    &q=Space+Needle,Seattle+WA"
                                    style={{ border: 0 }}
                                    allowFullScreen={false}
                                    aria-hidden="false"
                                    tabIndex={0}
                                />
                            </div>
                            <div className="w-full sm:w-[49%] sm:float-right ml-[2%] flex flex-col text-sm font-BasierSquare">
                                <span className="text-lg sm:text-xs">Horario</span>
                                <div className="flex flex-row sm:flex-col text-xl sm:text-md">
                                    <span> {schedule.time}Hrs. </span>
                                    <span className="ml-1 italic font-bold sm:text-sm">({schedule.open?'Abierto':'Cerrado'})</span>
                                </div>
                                <span className="text-lg sm:text-xs mt-1">Telefono</span>
                                <span className='text-xl sm:text-md'>{business.phone}</span>
                            </div>
                        </div>
                        <div className="w-full h-auto sm:h-[30%] flex flex-col sm:flex-row mt-1">
                            <div className="flex flex-col w-full sm:w-[60%]">
                                <span className="text-lg sm:text-xs">Ubicación</span>
                                <span className='text-xl sm:text-md'>{business.address}</span>
                            </div>
                            <div className="w-full sm:w-[40%] mt-6 sm:mt-0 mb-4 flex flex-row items-center justify-center">
                                {business.url_fb && <a href={business.url_fb}><img className="h-12 sm:h-8 ml-6 sm:ml-3 cursor-pointer" src={logo_fb} alt="facebook"></img></a>}
                                {business.url_insta && <a href={business.url_insta}><img className="h-12 sm:h-8 ml-6 sm:ml-3 cursor-pointer" src={logo_insta} alt="instagram"></img></a>}
                                {business.url_aldente && <a href={business.url_aldente}><img className="h-12 sm:h-8 ml-6 sm:ml-3 cursor-pointer" src={logo_aldente} alt="aldente"></img></a>}
                            </div>
                        </div>
                        <div className="w-full h-12 mt-1 flex flex-row font-BasierSquare">
                            <div className="mr-2 w-[50%] h-full flex flex-row items-center ">
                                <button className="cursor-pointer" onClick={()=>giveLikeBusiness(true)}>
                                    <img className="h-[50%] w-[90%]" src={like} alt="like"></img>
                                </button>
                                <div className="flex flex-col ml-1 text-center">
                                    <span className="text-sm">Recomienda</span>
                                    <span className="text-xs text-gray">({business.likes} personas)</span>
                                </div>
                            </div>
                            <div className="ml-2 w-[50%] h-full flex flex-row items-center">
                                <button className="cursor-pointer" onClick={()=>giveLikeBusiness(false)}>
                                    <img className="h-[50%] w-[90%]" src={dislike} alt="dislike"></img>
                                </button>
                                <div className="flex flex-col ml-1 text-center ">
                                    <span className="text-sm">No Recomienda</span>
                                    <span className="text-xs text-gray">({business.dislikes} personas)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EmptyGallery = () => {
    return(
        <div className='bg-gradient-to-t from-pink_ziti to-pale_salmon flex flex-col items-center justify-center w-full h-full rounded-2xl'>
            <img className="h-[50%]" src={ziti_logo}></img>
        </div>
    )
}

export default BusinessDetail;