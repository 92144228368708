
type BusinessCardProps = {
    background: string,
    description: string,
    name: string,
    logo: string,
    onclick: (item: any) => void
}

const BusinessCard = ({background, description, name, logo, onclick}: BusinessCardProps) => {
    return(
        <button className="h-44 text-white cursor-pointer" onClick={()=>onclick(name)}>
            <div className="w-full h-32 flex items-center justify-center rounded-lg">
                <img src={background} className='w-full h-full rounded-lg' alt={name}/>
                <div className=" w-24 h-20 absolute justify-center">
                    {/* <div className="bg-black/25 w-full h-full flex flex-col p items-center">
                        <p className='font-ArialRounded text-center text-white text-2xl h-24'>promo text</p>
                    </div> */}
                </div>
            </div>
            
            <div className='flex h-12 bg-brownish_grey_two rounded-lg px-1 sm:px-0'>
                <div className='h-12 sm:w-[40%] md:w-[35%] lg:w-[30%] flex items-center justify-center'>
                    <img src={logo} className="h-10 w-10" alt="ZiTi" />
                </div>
                <div className='h-12 sm:w-[60%] md:w-[65%] lg:w-[70%] text-left'>
                    <p className='font-BasierSquare text-sm truncate overflow-hidden text-nowrap'>{name}</p>
                    <p className='font-BasierSquare text-xs truncate italic mt-1'>{description}</p>
                </div>
            </div>
        </button>
    )
}

export default BusinessCard;