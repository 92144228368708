import { validateString } from '../../utils';

type SearchBarProps = {
    placeHolder: string,
    description: string|null,
    inputCallback: (text: string) => void
}

const SearchBar = ({placeHolder, description, inputCallback}:SearchBarProps) =>{
    const handleSearchInput = (val: any) => {
        if(validateString(val, true)){
            console.log(val)
            inputCallback(val.toLowerCase());
        }
        else if(val === ''){
            inputCallback('');
        }
    }

    return(
        <div className="mt-14 sm:w-[50%] flex-col items-center justify-center">
            <div>
                <input className='bg-white w-full h-10 border-2 border-pinkishGrey focus:border-pinkishGrey rounded-full p-2' 
                    type="text" placeholder={placeHolder} onChange={(e) => handleSearchInput(e.target.value)}/>
            </div>
            {description !== '' &&
                <div className='bg-brownish_grey px-4 rounded-b-lg pb-1 mt-[-1.25rem] pt-6'>
                    <p className='text-white text-sm '>
                        {description}
                    </p>
                </div>
            }
        </div>
    )
}

export default SearchBar;
