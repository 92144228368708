import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useUserContext } from "../../store/user";
import useZitiDiscountsData from "../../hooks/useZitiDiscountsData";

import { getAssetUrl } from "../../utils";

import SearchBar from "../../components/SearchBar";
import ZitiDiscountCard from "../../components/ZitiDiscountCard";
import Loading from "../../components/Loading";

import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import ziti from '../../assets/ziti_icon.png'

const ZitiDiscounts = () => {
    const [filter, setFilter] = useState<string>('');

    const { email } = useUserContext();
    const navigate = useNavigate();
    const { isLoading, data } = useZitiDiscountsData();

    useEffect(()=> {
        const profileLocal = localStorage.getItem('userProfile');
        if(!profileLocal){
           navigate('/Login');
        }
    },[]);

    const renderPromosData = () => {
        let arrayData:any =[];

        if(data.length > 0){
            const filteredData = filter===''? data : data.filter((promo:any) =>
                promo.bName.toLowerCase().lastIndexOf(filter.toLowerCase()) >= 0 ||
                promo.description.toLowerCase().lastIndexOf(filter.toLowerCase()) >= 0
            );

            if(filteredData.length >0){
                filteredData.map((promotion: any) => {
                    arrayData.push(
                        <ZitiDiscountCard logo={getAssetUrl(promotion.businessId, promotion.bLogo)} name={promotion.bName} description={promotion.bDescription} textPromo={promotion.description} />
                    );
                });

                return arrayData;
            }
        }

        return (
            <div className='col-span-3 items-center justify-center flex mt-6 flex-col'>
                <FontAwesomeIcon className='h-14' icon={faCircleExclamation} />
                <div className='mt-4 w-[60%] font-ArialRounded text-3xl text-center'>
                    <span>Estamos agregando descuentos exclusivos para ti.</span>
                </div>
            </div>
        );
    }

    return(
        <div className='flex flex-col items-center'>
            <SearchBar placeHolder='¿Que estas buscando?' 
                description='Encuentra el negocio, producto o servicio que estes buscando.' 
                inputCallback={setFilter}/>
            <div className="mt-2 mb-4 grid sm:grid-cols-3 w-[95%] sm:w-full lg:w-[90%] xl:w-[80%] 2xl:w-[60%] gap-0 gap-y-4 sm:gap-4 lg:gap-10 p-2">
                { isLoading? <Loading /> : 
                    renderPromosData() 
                }
            </div>
        </div>
    );
}

export default ZitiDiscounts;