import { FC, useState, useEffect, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface ISliderProps {
    gallery: Array<ReactNode>,
    emptyGalleryObject: ReactNode,
    autoSlide: boolean,
    autoSlideInterval: number,
    showButtons: boolean
}

const Slider:FC<ISliderProps> = ({ gallery, emptyGalleryObject, autoSlide, autoSlideInterval, showButtons }) => {
    const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? gallery.length - 1 : curr - 1))
  const next = () =>{
    setCurr((curr) => (curr === gallery.length - 1 ? 0 : curr + 1))
  }
    

  useEffect(() => {
    if (!autoSlide || gallery.length <= 1) return;
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [gallery]);

  return (
    <div className="relative h-full w-full overflow-hidden">
      { gallery.length >0?
        <>
          <div className="transition-transform ease-out duration-500 w-full h-full flex items-center"
            style={{ transform: `translateX(-${curr * 100}%)` }} >
            { gallery.map((item: ReactNode, index:number) => {
                return(<div className='h-full w-full flex-none' key={index}> {item} </div>)
              }) 
            }
          </div>

          {/* Buttons */}
          { showButtons &&
              <div className="absolute inset-0 flex items-center justify-between p-4 ">
                <button onClick={prev}
                  className="p-1 rounded-full shadow bg-white/20 text-gray-800 hover:bg-white h-16">
                  <FontAwesomeIcon icon={faChevronLeft} className='h-10 w-4'/>
                </button>
                <button onClick={next}
                  className="p-1 rounded-full shadow bg-white/62 text-gray-800 hover:bg-white h-16">
                  <FontAwesomeIcon icon={faChevronRight} className='h-10 w-4'/>
                </button>
              </div>
          }
          
        
          {/* Indicators */}
          <div className="absolute bottom-4 right-0 left-0">
            <div className="flex items-center justify-center gap-2">
              {gallery.map((_:any, i:number) => (
                <div
                  className={`
                  transition-all w-3 h-3 bg-white rounded-full
                  ${curr === i ? "p-2" : "bg-opacity-50"}
                `}
                />
              ))}
            </div>
          </div>
        </>
        :emptyGalleryObject
      }
      
    </div>
  )
}

export default Slider;