import React from 'react';
import Lottie from 'react-lottie-player';

import loading from '../../assets/animations/loading.json';

const Loading = () => {
    return(
        <>
            <Lottie animationData={loading} play loop/>
        </>
    )
}

export default Loading;