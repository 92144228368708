import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import useSubCategoriesData from '../../hooks/useSubCategoriesData';
import { useZitiContext } from "../../store";
import { useBusinessContext, BusinessInfo } from "../../store/business";

import { getAssetUrl } from "../../services/ziti.business";

import SearchBar from '../../components/SearchBar';
import Loading from "../../components/Loading";
import BusinessCard from "../../components/BusinessCard";

import ziti_logo from '../../assets/ziti_icon.png';

const Business = () =>{
    const [filter, setFilter] = useState('');

    const { selectedSubcategory } = useZitiContext();
    const { selectBusiness } = useBusinessContext();

    const { data, isLoading } = useSubCategoriesData(selectedSubcategory.id);
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.cancelQueries({ queryKey: ['SubCategoriesBusiness'] });
    },[location]);
    
    useEffect(()=>{
        if(!selectedSubcategory || selectedSubcategory.id === ''){
            backToCategories();
        }
    },[]);

    const backToCategories = () => {
        navigate('/', {replace:true})
    }

    const handleBusinessClick = (business: any) => {
        let businessData: BusinessInfo ={
            geolocalization: business.geolocalization,
            likes: business.likes,
            dislikes: business.dislikes,
            id: business._id,
            userName: business.userName,
            name: business.name,
            description: business.description,
            email: business.email,
            phone: business.phone,
            address: business.address,
            url: business.url,
            url_fb: business.url_fb,
            url_insta: business.url_insta,
            url_aldente: business.url_aldente,
            addedDate: business.addedDate,
            subcategoriesId: business.subcategoriesId,
            img_logo: business.img_logo,
            img_background: business.img_background,
            mainBanner: business.mainBanner,
            secondBanner: business.secondBanner,
            assetUrl: business.assetUrl,
            schedule: business.schedule
        }
        selectBusiness(businessData);
        navigate(business.userName);
    }

    const renderContent = () => {
        if(isLoading) return <Loading />
        let arrayContent:any = [];

        if(data){
            const filteredData = filter===''? data : data.filter((subCatItem: any) => subCatItem.name.toLowerCase().lastIndexOf(filter) >= 0,);
            
            filteredData.map((subCatItem: any) => {
                const assets = getAssetUrl(subCatItem.userName, subCatItem.logo, subCatItem.background);
                
                subCatItem.img_logo = assets.logo;
                subCatItem.img_background = assets.background;
                subCatItem.assetUrl = assets.baseUrl;

                arrayContent.push(<BusinessCard key={subCatItem._id} background={assets.background} logo={assets.logo} name={subCatItem.name} 
                    description={subCatItem.description} onclick={(id) => handleBusinessClick(subCatItem)}/>)
            });

            return arrayContent;
        }
        
    }

    return(
        <div className="p-4 flex-col flex justify-center items-center">
            <SearchBar placeHolder='¿Que estas buscando?' 
                description='Encuentra el negocio, producto o servicio que estes buscando.' 
                inputCallback={setFilter}/>
            <p className="text-3xl mt-2 font-ArialRounded">-{selectedSubcategory.label}-</p>
            <div className='w-full mt-10 flex items-center justify-center mb-10'>
                <div className='w-[90%] lg:w-[75%] xl:w-[60%] grid sm:grid-cols-3 md:grid-cols-4 gap-x-2 xl:gap-x-4 gap-y-4'>
                    { renderContent() }
                </div>
            </div>
        </div>
    )
}

export default Business;