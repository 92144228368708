type PromotionCardProps = {
    promoText: string,
    bgImage: string
}

const PromotionCard = ({promoText, bgImage}: PromotionCardProps) => {
    return(
        <div className="border border-sun_yellow bg-sun_yellow/10 h-28 w-32 p-1 rounded-xl inline-block relative ml-2 mr-2">
            <img className="h-full w-full" src={bgImage} alt="ziti" />
            <div className="w-[80%] h-[80%] absolute top-[10%] left-[10%]">
                <div className="bg-black/20 w-full h-full flex flex-col p-1 items-center justify-center text-white">
                    <span className='font-ArialRounded text-md text-wrap text-center overflow-hidden'>{promoText}</span>
                </div>
            </div>
        </div>
    )
}

export default PromotionCard;