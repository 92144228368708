import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SUPPORT_MAIL, SUPPORT_WP } from '../../constants';

import { useUserContext } from '../../store/user';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import ziti_logo from '../../assets/ziti_icon.png';
import profile_img from '../../assets/Perfil.png';
import like from '../../assets/like.png';
import dislike from '../../assets/notlike.png';


const Profile = () => {
    const navigate = useNavigate();
    const { name, lastName, email, likes, dislikes, clearProfile } = useUserContext();

    const goToExclusiveDiscounts = () => {
        navigate('/Ziti');
    }

    const closeSession = () => {
        clearProfile();
        localStorage.removeItem('userProfile');
        navigate('/');
    }

    return(
        <div className="p-4 font-BasierSquare flex justify-center">
            <div className="rounded-lg sm:border-gray/10 sm:bg-pale_salmon/20 sm:border
                flex flex-col items-center sm:items-start sm:flex-row w-full sm:w-full lg:w-[70%] xl:w-[55%] 2xl:w-[45%]">
                <div className='w-[50%] sm:w-[30%] flex flex-col items-center mt-5'>
                    <img className='sm:h-28 sm:w-28' src={profile_img} />
                    <button className='bg-sun_yellow  hidden sm:block p-2 w-[85%] rounded-lg hover:shadow-lg mt-16'
                        onClick={() => closeSession()}>
                        <FontAwesomeIcon className='mr-2' icon={faRightFromBracket} />Cerrar Sesión
                    </button>
                </div>
                <div className='w-full sm:w-[70%] sm:p-1'>
                    <div className='flex flex-col items-center sm:items-start'>
                        <span className='font-bold text-2xl mt-5 capitalize'>{name + ' ' + lastName}</span>
                        <span className='ml-1 mt-1 text-sm'>{email}</span>
                    </div>
                    <div className='w-full mt-8 flex flex-row'>
                        <div className="flex flex-row w-full p-2 items-center justify-center">
                            <img className="h-6 sm:h-[60%]" src={like} alt="like" />
                            <div className="flex flex-col ml-2 sm:ml-5 text-left">
                                <span className="text-sm">Has recomendado</span>
                                <span className="text-xs text-gray">({likes.length} Establecimientos)</span>
                            </div>
                        </div>
                        <div className="flex flex-row w-full p-2 items-center justify-center">
                            <img className="h-6 sm:h-[60%]" src={dislike} alt="dislike" />
                            <div className="flex flex-col ml-2 sm:ml-5 text-left">
                                <span className="text-sm">No recomiendas</span>
                                <span className="text-xs text-gray">({dislikes.length} Establecimientos)</span>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10 p-1 flex flex-col items-center'>
                        <span className="text-2xl mb-4">Descuentos Exclusivos</span>
                        <button className="bg-pink_ziti p-3 rounded-full hover:shadow-xl" 
                            onClick={() => goToExclusiveDiscounts()}>
                            <img className='h-14 w-14' src={ziti_logo}></img>
                        </button>
                    </div>
                    <div className="flex flex-col items-center mt-8 mb-4">
                        <span className='text-md font-bold text-center'>¿Tienes algún problema o alguna duda?</span>
                        <span className='text-sm'>Contacta a soporte técnico</span>
                        <div className='w-[50%] flex flex-row justify-center mt-2'>
                            <a href={'mailto:' + SUPPORT_MAIL}>
                                <FontAwesomeIcon className='h-10 w-10 mr-10' icon={faEnvelope} />
                            </a>
                            <a href={'https://wa.me/1' + SUPPORT_WP}>
                                <FontAwesomeIcon className='h-10 w-10 ml-10' icon={faWhatsapp} />
                            </a>
                        </div>
                        <button className='bg-sun_yellow  block sm:hidden p-2 w-[85%] rounded-lg hover:shadow-lg mt-16'
                            onClick={() => closeSession()}>
                            <FontAwesomeIcon className='mr-2' icon={faRightFromBracket} />Cerrar Sesión
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;