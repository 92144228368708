import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './App.css';

import ZitiContextProvider from './store';
import BusinessContextProvider from './store/business';
import UserContextProvider from './store/user';

import RouterApp from './routes';

const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <ZitiContextProvider>
          <BusinessContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <RouterApp />
          </BusinessContextProvider>
        </ZitiContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
}

export default App;
