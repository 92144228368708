import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUserContext } from '../../store/user';

import ziti_logo from '../../assets/ziti_icon.png';
import user from '../../assets/user.png';

const MenuBar = () => {
    const [linkTo, setLinkTo] = useState('Login');

    const {email} = useUserContext();

    useEffect(() => {
        email !== ''? setLinkTo('Profile'): setLinkTo('Login');
    },[email]);

    return(
        <div className="bg-gradient-to-t from-pink_ziti to-pale_salmon h-16 flex flex-row items-center justify-center font-BasierSquare text-white">
            <div className="flex flex-row items-center w-full sm:w-[90%] lg:w-[70%] xl:w-[50%]">
                <Link to='/' ><img src={ziti_logo} alt="ziti" className='h-full w-14 cursor-pointer'/></Link>
                <span className='text-md sm:text-2xl ml-2 mr-2 sm:mr-0 sm:ml-4 flex-grow text-center'>Descuentos por toda la ciudad</span>
                <Link to={linkTo}>
                    <img className='w-9 mr-2 sm:mr-0 sm:w-7 cursor-pointer fill-white' src={user} alt="users"/>
                </Link>
            </div>
        </div>
    )
}

export default MenuBar;