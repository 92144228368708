import React, { createContext, useContext, FC, useState } from 'react';

export type SelectedCategory = {
    id: string,
    label: string,
    subCategories: any,
}

export type SelectedSubCategory = {
    id: string,
    label: string,
}

interface IZitiContextType {
    selectedCategory: SelectedCategory,
    selectedSubcategory: SelectedSubCategory,
    setCategory: (selectedCat: SelectedCategory) => void,
    setSubCategory: (subCategory: SelectedSubCategory) => void,
}

const initialState: IZitiContextType = {
    selectedCategory: {id: '', label: '', subCategories: []},
    selectedSubcategory: {id:'', label:''},
    setCategory: ({}) => {},
    setSubCategory: ({}) => {},
}

const ZitiContext = createContext<IZitiContextType>(initialState);

export const useZitiContext = () => useContext(ZitiContext);

const ZitiContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
    const [selectedCategory, setSelectedCategory] = useState({id: '', label: '', subCategories: []});
    const [selectedSubcategory, setSelectedSubCategory] = useState(initialState.selectedSubcategory);
    
    const setCategory = (selectedCat: SelectedCategory) => { setSelectedCategory(selectedCat); }

    const setSubCategory = (subCategory: SelectedSubCategory) => { setSelectedSubCategory(subCategory); }

    return(
        <ZitiContext.Provider value={{selectedCategory, setCategory, selectedSubcategory, setSubCategory}}>
            {children}
        </ZitiContext.Provider>
    )
}

export default ZitiContextProvider;
