import { useQuery } from '@tanstack/react-query';

import { getPromosByBusiness } from '../services/ziti.business';

const QUERY_KEY:any = ['PromosBusiness'];

enum PromotionType  {
    discount,
    promo,
    product
}

export type Promotion = {
    _id: string,
    product: string,
    description: "descuento en cafe americano",
    discountAmount: "10",
    type: PromotionType,
    requirement: string,
    businessId: string,
    usersOnly: boolean,
    mainPromo: boolean,
    image: string
}

const fetchData = async(businessUser: string) => {
    const result = await getPromosByBusiness(businessUser);
    console.log('getPromosByBusiness: ' + businessUser);
    return result;
}

const usePromotionsData = (subCatId: string) => {
    return useQuery({queryKey: [QUERY_KEY, subCatId], queryFn:() => fetchData(subCatId) , refetchInterval:30000, enabled:!!subCatId});
}

export default usePromotionsData;