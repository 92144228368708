import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';

import MenuBar from '../../components/MenuBar';
import FooterBar from '../../components/FooterBar';

const Layout = () => {
    return(
        <>
            <MenuBar />
            <Outlet />
            {/* <FooterBar /> */}
        </>
    )
}

export default Layout;