import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useCategoriesData from '../../hooks/useCategoriesData';
import { useZitiContext } from '../../store';

import ItemButton from '../../components/ItemButton';
import SearchBar from '../../components/SearchBar';
import Loading from '../../components/Loading';

import logo from '../../assets/ziti_icon.png';

const Home = () => {
    const [filter, setFilter] = useState('');
    
    const navigate = useNavigate();
    const { data, isLoading } = useCategoriesData();
    const { setCategory } = useZitiContext();

    const renderCats = () => {
        let arrayData:any =[];
        if(data){
            const filteredData = filter===''? data : data.filter((category: any) => category.name.toLowerCase().lastIndexOf(filter) >= 0,);

            filteredData.map((cat: any) => {
                arrayData.push(<ItemButton id={cat._id} label={cat.name} key={cat._id} subCategories={cat.subcategoriesId} clickCallback={handleClickCategory}/>);
            });
            return arrayData;
        }
        return (<></>);
    }

    const handleClickCategory = (id: string, label: string, subCategories: any) => {
        setCategory({id, label, subCategories});
        navigate('Categories');
    }
    
    return (
        <div className="p-4 flex-col flex justify-center items-center">
            <div>
                <img src={logo} className="h-32" alt="ZiTi" />
            </div>
                <SearchBar placeHolder='¿Que estas buscando?' 
                    description='Encuentra el negocio, producto o servicio que estes buscando.' 
                    inputCallback={setFilter}/>
                { isLoading ? 
                    <div className='w-[30%] mt-10'><Loading /></div> : 
                    <div className='w-full mt-10 flex items-center justify-center mb-10'>
                        <div className='w-[90%] lg:w-[75%] xl:w-[60%] grid sm:grid-cols-2 gap-x-8 gap-y-4'>
                            {renderCats()}
                        </div>
                    </div>
                }
        </div>
    )
}

export default Home;